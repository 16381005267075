import * as React from 'react'
import clsx from 'clsx'
import { Link, LinkProps } from '@mui/material'
import styles from './IconTextLink.module.css'

export type IconTextLinkProps = LinkProps &
  React.PropsWithChildren &
  Readonly<{
    text: string
  }>

// TODO: Refactor it (three is nothing about an icon)
export const IconTextLink: React.FC<IconTextLinkProps> = ({ text, className, children, ...linkProps }) => (
  <Link className={clsx(styles.iconLink, className)} {...linkProps}>
    {children}
    <span className="icon-link-text">{text}</span>
  </Link>
)
